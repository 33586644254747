// Import React modules
import React, { Fragment } from 'react';

// Import packages
import { Container, Row, Col, FormControl, InputGroup, Button } from 'react-bootstrap';
import styled from 'styled-components';
import { FaLinkedinIn, FaGithub, FaCodepen, FaTwitter } from 'react-icons/fa';
import { IoIosArrowDroprightCircle } from 'react-icons/io';

// Custom styles
const FooterContainer = styled.footer`
  .footer-middle {
    background: var(--mainDark);
    padding-top: 3rem;
    color: var(--mainWhite);
  }

  ul li a {
    color: var(--mainGrey);
  }

  ul li a:hover  {
    color: var(--mainBlue);
    text-decoration: none;
  }

  ul li a:active  {
    color: var(--mainLightGrey);
  }

  .icon-override {
    margin-bottom: 0.2rem;
    margin-right: 0.5rem;
  }

  .footer-bottom {
    margin-top: 1rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
`;

const H5 = styled.h5`
  margin-bottom: 2rem;
  color: var(--mainWhite);
`;

const LIST = styled.li`
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const P = styled.p`
  margin-top: 0.5rem;
  font-size: 1rem;
`;

function NewFooter() {

  const getCurrentYear = () => {
    return new Date().getFullYear();
  };

  return (
    <Fragment>
      <FooterContainer>
        <div className="footer-middle">
          <Container >
            <Row className="justify-content-around">
              {/* START: Column 1 */}
              <Col md={2} sm={6}>
                <H5>ABOUT</H5>
                <ul className="list-unstyled">
                  <LIST><a href="/about">Story</a></LIST>
                  <LIST><a href="/">Clients</a></LIST>
                  <LIST><a href="/">Testimonials</a></LIST>
                </ul>
              </Col>
              {/* END: Column 1 */}

              {/* START: Column 2 */}
                <Col md={2} sm={6}>
                  <H5>BLOG</H5>
                  <ul className="list-unstyled">
                    <LIST><a href="/">ReactMelb Meetup</a></LIST>
                    <LIST><a href="/">NEW: Project</a></LIST>
                    <LIST><a href="/">EDIT: Project</a></LIST>
                  </ul>
                </Col>
              {/* END: Column 2 */}

              {/* START: Column 3 */}
              <Col md={2} sm={6}>
                  <H5>PROJECTS</H5>
                  <ul className="list-unstyled">
                    <LIST><a href="/">Laravel Quiz</a></LIST>
                    <LIST><a href="/">E-shop</a></LIST>
                    <LIST><a href="/">Taskman</a></LIST>
                    <LIST><a href="/">Jobs4U</a></LIST>
                  </ul>
                </Col>
              {/* END: Column 3 */}

              {/* START: Column 4 */}
              <Col md={2} sm={6}>
                  <H5>SOCIALS</H5>
                  <ul className="list-unstyled">
                    <LIST>
                      <a href="/">
                        <FaLinkedinIn className="icon-override"/>
                        {' '}LinkedIn
                      </a>
                    </LIST>
                    <LIST>
                      <a href="/">
                        <FaGithub className="icon-override"/>
                        {' '}GitHub
                      </a>
                    </LIST>
                    <LIST>
                      <a href="/">
                        <FaCodepen className="icon-override"/>
                        {' '}Codepen.io
                      </a>
                    </LIST>
                    <LIST>
                      <a href="/">
                        <FaTwitter className="icon-override"/>
                        {' '}Twitter
                      </a>
                    </LIST>
                  </ul>
                </Col>
              {/* END: Column 4 */}

            </Row>

            {/* Footer Bottom */}
            <Row className="footer-bottom justify-content-center border-top">
              <Col md={1}>
                <P>Sign Up: </P>
              </Col>
              {/* START: Col 2 [Subscription] */}
              <Col md={4}>
              <InputGroup className="mb-3">
                <FormControl
                  placeholder="Email"
                  aria-label="Subscribers's email"
                  aria-describedby="basic-addon2"
                />
                <Button variant="outline-info" id="button-addon2">
                  <IoIosArrowDroprightCircle />
                </Button>
              </InputGroup>
              </Col>
              {/* END: Col 2 [Subscription] */}

              {/* START: Col 2 [Signature] */}
              <Col md={{ span: 3, offset: 3 }}>
                <P className="text-right pr-5">
                  &copy; {getCurrentYear()} Sanjith Das
                </P>
              </Col>
              {/* END: Col 2 [Subscription] */}
            </Row>
          </Container>
        </div>
      </FooterContainer>
    </Fragment>
  )
}

export default NewFooter