import React from 'react'

//Import packages
import { Container , Button, Row, Col  } from 'react-bootstrap';
 
const Projects = () => {
  return (
    <Container id="main">
      <h1>Projects</h1>
      <Row>
          {/* START: Project 1 */}
          <Col lg={4} md={6} className="mt-4 pt-2">
            <div className="blog-post rounded border">
              <div className="blog-img d-block overflow-hidden position-relative">
                <img src="exam.png" alt="" className="img-fluid rounded-top" />
                <div className="overlay rounded-top bg-dark"></div>
                <div className="post-meta">
                  <a href="https://sdcreationz.com/quizapp" className="text-light d-block text-right like font-weight-bold">Visit</a>
                 
                </div>
              </div>

              <div className="content p-3">
                 
                <h4 className="mt-2"><a href="https://sdcreationz.com/quizapp" className="text-dark title">Quiz/Exam</a></h4>
                <p className="text-muted mt-2">Online examination site developed using 
                  <b> <i>PHP, Laravel 8, Tailwind css , JavaScript , MySQL, Bootstrap and JQuery </i></b>. Includes user module and admin module. Sanctom token authentication is using to authenticate users.
                  {/* <div>
                  Laravel features included are:
                  <ul>
                     
                      <li>
                        Jetstream Autentication
                      </li>
                      <li>
                       Sanctum token verification
                      </li>
                      <li>
                       Laravel Gates authorisation
                      </li>
                      <li>
                       Laravel Middleware (Custom) and so on...
                      </li>
                  </ul>
                  </div> */}
                  

                 </p>
                <div className="pt-3 mt-3 border-top d-flex">
                  <img src="https://bootdey.com/img/Content/avatar/avatar7.png" alt="" className="img-fluid avatar avatar-ex-sm rounded-pill mr-3 shadow" />
                  <div className="author mt-2">
                    <h6 class="mb-0"><a href="/" className="text-dark name">Sanjith Das</a></h6>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          {/* END: Project  1 */}

          {/* START: Project 2 */}
          <Col lg={4} md={6} className="mt-4 pt-2">
            <div className="blog-post rounded border">
              <div className="blog-img d-block overflow-hidden position-relative">
                <img src="cart.png" alt="" className="img-fluid rounded-top" />
                <div className="overlay rounded-top bg-dark"></div>
                <div className="post-meta">
                  <a href="https://sdcreationz.com/cart/shops" className="text-light d-block text-right like font-weight-bold">Visit</a>
                   
                </div>
              </div>

              <div className="content p-3">
                 
                <h4 className="mt-2"><a href="https://sdcreationz.com/cart/shops" className="text-dark title">E-shop</a></h4>
                <p className="text-muted mt-2">Design e-commerce system allowing users to purchase 
                  products online. Stripe payment gateway is included to manage the payment. The site is developed by using <b> <i> PHP, Laravel6, Bootstrap,JavaScript,MySQL, JQuery </i></b>. Includes user module and admin module
                  </p>
                <div className="pt-3 mt-3 border-top d-flex">
                  <img src="https://bootdey.com/img/Content/avatar/avatar7.png" alt="" className="img-fluid avatar avatar-ex-sm rounded-pill mr-3 shadow" />
                  <div className="author mt-2">
                    <h6 class="mb-0"><a href="/" className="text-dark name">Sanjith Das</a></h6>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          {/* END: Project 2 */}

          {/* START: Project 3 */}
          <Col lg={4} md={6} className="mt-4 pt-2">
            <div className="blog-post rounded border">
              <div className="blog-img d-block overflow-hidden position-relative">
                <img src="job.png" alt="" className="img-fluid rounded-top" />
                <div className="overlay rounded-top bg-dark"></div>
                <div className="post-meta">
                  <a href="https://nadhammelbourne.com.au/jobs/" className="text-light d-block text-right like font-weight-bold">Visit</a>
                 
                </div>
              </div>

              <div className="content p-3">
                 
                <h4 className="mt-2"><a href="https://nadhammelbourne.com.au/jobs/" className="text-dark title">Jobs4U</a></h4>
                <p className="text-muted mt-2">A complete Job Portal developed by using 
                <b> <i> Laravel, VueJS , MySQL, CSS, JavaScript etc.</i> </b>
                The main users are Job seeker , company and admin. GitHub authentication is also given. 
                Blog funcionality is also included.
                 </p>
                <div className="pt-3 mt-3 border-top d-flex">
                  <img src="https://bootdey.com/img/Content/avatar/avatar7.png" alt="" className="img-fluid avatar avatar-ex-sm rounded-pill mr-3 shadow" />
                  <div className="author mt-2">
                    <h6 class="mb-0"><a href="/" className="text-dark name">Sanjith Das</a></h6>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          {/* END: Project 3 */}

           {/* START: Project 4 */}
           <Col lg={4} md={6} className="mt-4 pt-2">
            <div className="blog-post rounded border">
              <div className="blog-img d-block overflow-hidden position-relative">
                <img src="task.png" alt="" className="img-fluid rounded-top" />
                <div className="overlay rounded-top bg-dark"></div>
                <div className="post-meta">
                  <a href="http://sdcreationz.com/taskman/" className="text-light d-block text-right like font-weight-bold">Visit</a>
                 
                </div>
              </div>

              <div className="content p-3">
                 <h4 className="mt-2"><a href="http://sdcreationz.com/taskman/" className="text-dark title">Taskman</a></h4>
                <p className="text-muted mt-2">
                  This is an API developed as a part of the accademic projects, by using ReactJS as the frontend and laravel as the backend .
                  This task management system includes the CRUD operations. Create/Read/Update and delete task. 
                  
                  </p>
                <div className="pt-3 mt-3 border-top d-flex">
                  <img src="https://bootdey.com/img/Content/avatar/avatar7.png" alt="" className="img-fluid avatar avatar-ex-sm rounded-pill mr-3 shadow" />
                  <div className="author mt-2">
                    <h6 class="mb-0"><a href="/" className="text-dark name">Sanjith Das</a></h6>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          {/* END: Project 4 */}

          <Col lg={4} md={6} className="mt-4 pt-2">
            <div className="blog-post rounded border">
              <div className="blog-img d-block overflow-hidden position-relative">
                <a href="https://nadhammelbourne.com.au/jobs/chat">
                  <img src="chat.png" alt="" className="img-fluid rounded-top" />
                </a>
                <div className="overlay rounded-top bg-dark"></div>
                <div className="post-meta">
                  <a href="https://nadhammelbourne.com.au/jobs/chat" className="text-light d-block text-right like font-weight-bold">Visit</a>
                 
                </div>
              </div>

              <div className="content p-3">
                
                <h4 className="mt-2"><a href="https://nadhammelbourne.com.au/jobs/chat" className="text-dark title">Chat Application</a></h4>
                <p className="text-muted mt-2">This chat application provides the flexibility to do private chat as well as group chat. It is implemented by using Laravel pusher broadcasting. Technology used to develop this application is <b><i>PHP, Laravel 6 , Bootstrap , MySQL, JQuery </i> </b> </p>
                <div className="pt-3 mt-3 border-top d-flex">
                  <img src="https://bootdey.com/img/Content/avatar/avatar7.png" alt="" className="img-fluid avatar avatar-ex-sm rounded-pill mr-3 shadow" />
                  <div className="author mt-2">
                    <h6 class="mb-0"><a href="/" className="text-dark name">Sanjith Das</a></h6>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          {/* END: Projct 5 */}


          {/* START: Project 6 */}
          <Col lg={4} md={6} className="mt-4 pt-2">
            <div className="blog-post rounded border">
              <div className="blog-img d-block overflow-hidden position-relative">
                <img src="nadham.png" alt="" className="img-fluid rounded-top" />
                <div className="overlay rounded-top bg-dark"></div>
                <div className="post-meta">
                  <a href="http://nadhammelbourne.com.au" className="text-light d-block text-right like font-weight-bold">Visit</a>
                 
                </div>
              </div>

              <div className="content p-3">
                <h4 className="mt-2"><a href="http://nadhammelbourne.com.au" className="text-dark title">
                www.nadhammelbourne.com.au
                  </a></h4>
                <p className="text-muted mt-2">
                  A non profit organisation started on 2008 as a small family group and now established to promote socio-cultural activities among kids and philanthropy.
                  We are also actively participate humanitarian activities like blood donations, charity services are just a few of them</p>
                <div className="pt-3 mt-3 border-top d-flex">
                  <img src="https://bootdey.com/img/Content/avatar/avatar7.png" alt="" className="img-fluid avatar avatar-ex-sm rounded-pill mr-3 shadow" />
                  <div className="author mt-2">
                    <h6 class="mb-0"><a href="/" className="text-dark name">Sanjith Das</a></h6>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          {/* END: Project 6 */}

        </Row>
    </Container>
  )
}

export default Projects