// Import React components
import React, { Fragment } from 'react';

// Import packages
// import styled from 'styled-components';
import { Container, Button, Row, Col } from 'react-bootstrap';
import { FiHeart } from 'react-icons/fi';
import { IoIosHeartEmpty, IoIosArrowDroprightCircle } from 'react-icons/io';

// Import custom component
import SubModal from '../layout/SubModal';

// Custom styles

const Blog = () => {

  const clearStorage = () => {
    sessionStorage.removeItem('popupModal');
    window.location.reload();
  }

  return (
    <Fragment>
      <Container id="main">

        {/* START: Title Row */}
        <Row>
          <Col md={12} className="text-center">
            <div className="section-title mb-4 pb-2">
              <h4 className="title mb-4">Latest SD Blog {'& '}News</h4>
              <p className="text-muted para-desc mx-auto mb-0">Check out my latest posts, projects and playful adventures in the programming industry and beyond!</p>
              <div className="mt-4">
                <SubModal />
                <Button variant="dark" onClick={clearStorage}>Subscribe Now!</Button>
              </div>
            </div>
          </Col>
        </Row>
        {/* END: Title Row */}

        {/* START: Blogs Row 1 */}
        <Row>
          {/* START: Blog Post 1 */}
          <Col lg={4} md={6} className="mt-4 pt-2">
            <div className="blog-post rounded border">
              <div className="blog-img d-block overflow-hidden position-relative">
                <img src="https://via.placeholder.com/350x280/6495ED/000000" alt="" className="img-fluid rounded-top" />
                <div className="overlay rounded-top bg-dark"></div>
                <div className="post-meta">
                  <a href="/" className="text-light d-block text-right like"><IoIosHeartEmpty />10</a>
                  <a href="/" className="text-light read-more">Read More <IoIosArrowDroprightCircle /></a>
                </div>
              </div>

              <div className="content p-3">
                <small className="text-muted p float-right">25 May, '21</small>
                <small><a href="/" className="text-primary">New Project</a></small>
                <h4 className="mt-2"><a href="/" className="text-dark title">Firestore-EJS</a></h4>
                <p className="text-muted mt-2">A new server-side rendering application using EJS to render views with Google Firestore integration</p>
                <div className="pt-3 mt-3 border-top d-flex">
                  <img src="https://bootdey.com/img/Content/avatar/avatar7.png" alt="" className="img-fluid avatar avatar-ex-sm rounded-pill mr-3 shadow" />
                  <div className="author mt-2">
                    <h6 class="mb-0"><a href="/" className="text-dark name">Sanjith Das</a></h6>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          {/* END: Blog Post 1 */}

          {/* START: Blog Post 2 */}
          <Col lg={4} md={6} className="mt-4 pt-2">
            <div className="blog-post rounded border">
              <div className="blog-img d-block overflow-hidden position-relative">
                <img src="https://via.placeholder.com/350x280/6495ED/000000" alt="" className="img-fluid rounded-top" />
                <div className="overlay rounded-top bg-dark"></div>
                <div className="post-meta">
                  <a href="/" className="text-light d-block text-right like"><FiHeart />10</a>
                  <a href="/" className="text-light read-more">Read More <IoIosArrowDroprightCircle /></a>
                </div>
              </div>

              <div className="content p-3">
                <small className="text-muted p float-right">25 May, '21</small>
                <small><a href="/" className="text-success">Blog Post</a></small>
                <h4 className="mt-2"><a href="/" className="text-dark title">ReactMelb Meetup</a></h4>
                <p className="text-muted mt-2">A recap of the long-awaited return of ReactMelb Meetup post-COVID19 and its' most interesting discussions!</p>
                <div className="pt-3 mt-3 border-top d-flex">
                  <img src="https://bootdey.com/img/Content/avatar/avatar7.png" alt="" className="img-fluid avatar avatar-ex-sm rounded-pill mr-3 shadow" />
                  <div className="author mt-2">
                    <h6 class="mb-0"><a href="/" className="text-dark name">Sanjith Das</a></h6>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          {/* END: Blog Post 2 */}

          {/* START: Blog Post 3 */}
          <Col lg={4} md={6} className="mt-4 pt-2">
            <div className="blog-post rounded border">
              <div className="blog-img d-block overflow-hidden position-relative">
                <img src="https://via.placeholder.com/350x280/6495ED/000000" alt="" className="img-fluid rounded-top" />
                <div className="overlay rounded-top bg-dark"></div>
                <div className="post-meta">
                  <a href="/" className="text-light d-block text-right like"><FiHeart />10</a>
                  <a href="/" className="text-light read-more">Read More <IoIosArrowDroprightCircle /></a>
                </div>
              </div>

              <div className="content p-3">
                <small className="text-muted p float-right">25 May, '21</small>
                <small><a href="/" className="text-info">Updated Project</a></small>
                <h4 className="mt-2"><a href="/" className="text-dark title">Rush.GG</a></h4>
                <p className="text-muted mt-2">Update includes improved GUI to display player metrics efficiently and improve user experience</p>
                <div className="pt-3 mt-3 border-top d-flex">
                  <img src="https://bootdey.com/img/Content/avatar/avatar7.png" alt="" className="img-fluid avatar avatar-ex-sm rounded-pill mr-3 shadow" />
                  <div className="author mt-2">
                    <h6 class="mb-0"><a href="/" className="text-dark name">Sanjith Das</a></h6>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          {/* END: Blog Post 3 */}

        </Row>
        {/* END: Blogs Row 1 */}

      </Container>
    </Fragment>
  )
}

export default Blog