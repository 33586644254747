// Import React components
import React, { useState } from 'react';

// Import packages
import axios from 'axios';
import { Container, Form, Button } from 'react-bootstrap';

const Contact = () => {
  // State management
  const [sent, setSent] = useState(false);
  const [formData, setformData] = useState({
    name: '',
    email: '',
    message: ''
  });

  // Destructure state (allow us to streamline access to object properties - instead of having to call formData.name etc.)
  const { name, email, message } = formData;

  // [A] onChange: Function to save new entry of data to the state 
  const handleOnChange = (e) => {
    setformData({ ...formData, [e.target.name]: e.target.value });
  };

  // [B] onSubmit: Function to (i) set "Sent" message state to show and (ii) POST the data in state to the proxy server
  const handleOnSubmit = async (e) => {
    setSent(true);
    e.preventDefault();
    const form = await axios.post('https://sanjithporfolio.herokuapp.com/api/contact', {
      name,
      email,
      message
    });
    console.log(form);
  };

  // Structure rendered to DOM
  return (
    <Container className="mb-5" id="main">
      <h1>Contact Us</h1>
      {!sent ? (
        <Form onSubmit ={ handleOnSubmit } >
          <Form.Group controlId="name">
            <Form.Label>Name: </Form.Label>
            <Form.Control
                type="text"
                placeholder="Enter name"
                name="name"
                value={name}
                onChange={ handleOnChange }
            />
          </Form.Group>

          <Form.Group controlId="email">
            <Form.Label>Email: </Form.Label>
            <Form.Control
                type="email"
                placeholder="Enter email address"
                name="email"
                value={email}
                onChange={ handleOnChange }
            />
          </Form.Group>

          <Form.Group controlId="message">
            <Form.Label>Message: </Form.Label>
            <Form.Control
                type="messagearea"
                as="textarea"
                rows="3"
                placeholder="Enter message here"
                name="message"
                value={message}
                onChange={ handleOnChange }
            />
          </Form.Group>

          <Button variant="dark" type="submit">Submit</Button>
        </Form>
      ) : (<h1>Email Sent</h1>)}
    </Container>
  );
}

export default Contact