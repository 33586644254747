// Import react modules
import React from 'react';

//Import packages
// import { Container } from 'react-bootstrap';
import styled from 'styled-components';

const ContentWrap = styled.div`
  margin-top: 3rem;
  margin-bottom: 0rem;
  flex: 1;
`;

const Layout = props => (
  // We wrap all other components within the Bootstrap Container formatting
  <ContentWrap>
    {props.children}
  </ContentWrap>
);

export default Layout