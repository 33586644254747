// Import React components
import React, { Fragment } from 'react'

//Import styling packages
import {Jumbotron as Jumbo, Container} from 'react-bootstrap';
import styled from 'styled-components';

//Import assets
//import heroProfile from '../../assets/abprofile.png'
import heroProfile from '../../assets/Dev3.png'
// Custom styles
const Styles = styled.div`
    .jumbo {
        background: url(${heroProfile}) no-repeat;
        
        /* SHIFT IMAGE LOCATION: We can move the image around in the viewport using background-position.  In this case, as the image is "cover", it's stretch to the width, so cannot move it left or right, only up or down */
        background-size: cover;
        background-position: bottom 90%;
        color: #FFFFFF;
        height: 700px;
        margin-top: 0;
        margin-bottom: 0;

        position: relative;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        /* OVERLAY SETUP: z-index allows one to stack elements on top of each other - the z-index indicates the "layer level" */
       
        scale: 1rem;
    }

    .overlay {
        background-color: #000;
        opacity: 0.5;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
    }

    .container {
        padding-top: 6rem;
    }

    .blinking-cursor {
        font-weight: 200;
        font-size: 20px;
        color: #2e3d48;
        animation: 1s blink step-end infinite;

        @keyframes blink {
            from,
            to {
            color: transparent;
            }
            50% {
            color: #FFFFFF;
            }
        }
    }
`;

const H1 = styled.h1`
  font-size: 2.5rem;
`;

const B = styled.b`
  font-weight: normal;
  color: #5892dd;
`;

const P = styled.p`
.test{
   background-color:red !important;
}
`;


const Home = () => {
  return (
    <Fragment>
      <Styles>
        <Jumbo className='jumbo'>
          <div className='overlay'></div>
          <Container className='container'>
              <H1>Sanjith Das</H1>
              <P className="test">

             Frontend   Developer <B>/</B> Backend Developer <B>/</B> Fullstack Developer
                {' '}
                <span className="blinking-cursor">|</span>
              </P>
          </Container>
        </Jumbo>
      </Styles>
    </Fragment>
  )
}

export default Home