// Import React components
import React, { useState, useEffect, Fragment } from 'react';

// Import packages
import axios from 'axios';
import styled from 'styled-components';
import { Button, Modal, InputGroup, FormControl } from 'react-bootstrap';
import { IconContext } from "react-icons";
import { FaRegEnvelopeOpen } from 'react-icons/fa'

// Custom styles
const H5 = styled.h5`
  font-size: 1.5rem;
`;

const P = styled.p`
  font-size: 1rem;
`;

const SubModal = () => {
  // Set state for the generalStateManagement, modal, email(form) & errorAlert
  const [state, setState] = useState("IDLE");
  const [modalShow, setModalShow] = useState(false);
  const [email, setEmail ] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);

  // Conditional Statement working with sessionStorage
  // NOTE: We are checking value for a sessionStorage value - if true, no popup / if false, popup and create sessionStorage value
  useEffect(() => {
    const popupModalValue = sessionStorage.getItem("popupModal")
    console.log(popupModalValue)

    if (popupModalValue === "true") {
      setModalShow(false);
    }

    else if (popupModalValue !== "true") {
      // REMINDER: setTimeout() takes two parameters - a function to be executed and how much time must pass before executing 
      const timer = setTimeout(() => {
        setModalShow(true)
        sessionStorage.setItem("popupModal", "true");
      }, 500);

      // REMINDER: clearTimeout() clears the timeout which has been set by setTimeout() so it only executes once when being called
      return () => clearTimeout(timer);
    }
  }, []);

  // Function to POST to server API on hitting Subscribe
  const onSubscribe = async () => {
    setState("LOADING")
    setErrorMessage(null);
    try {
      const response = await axios.post('/api/blog', {email});
      console.log(response);
      setState("SUCCESS");
    } catch (e) {
      setErrorMessage(e.response.data.error);
      setState("ERROR");
    }
  };

  // Structure rendered to DOM
  return (
    <Fragment>
      <Modal
        show={modalShow} 
        onHide={() => setModalShow(false)}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
        centered
      >
        <Modal.Header className="mx-auto border-0">
          {/* IconContext.Provider styles React Icons - see API: https://github.com/react-icons/react-icons#configuration */}
          <IconContext.Provider
            value={{ color: '#17a2b8', size: '130px' }}>
            <FaRegEnvelopeOpen />
          </IconContext.Provider>
        </Modal.Header>
        <Modal.Body className="mx-auto text-center">
          <H5>Subscribe to SD Blog</H5>
          <P>Join fellow blog subscribers to receive my latest news, updates and newest Git repos!</P>
          <InputGroup className="mt-3 mb-3">
            <FormControl
              type="email"
              placeholder="Enter your email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <InputGroup.Append>
              <Button 
                variant="info" 
                className={state === "LOADING" ? "button-gradient-loading" : ""}
                type="button" 
                disabled={state === "LOADING"}
                onClick={onSubscribe}
              >
                Subscribe
              </Button>
            </InputGroup.Append>
          </InputGroup>
          {state === "ERROR" && (
            <P className="w-1/2 mt-2 text-danger">{errorMessage}</P>
          )}
          {state === "SUCCESS" && (
            <P className="w-1/2 mt-2 text-success">Thank you for subscribing to the AB Programming Blog!</P>
          )}
        </Modal.Body>
      </Modal>
    </Fragment>
  )
} 

export default SubModal