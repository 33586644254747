import React from 'react'

//Import packages
import { Container } from 'react-bootstrap';

const About = () => {
  return (
    <Container id="main">
      <h1>Programmer Profile</h1>
      <p>Sorry! This is currently a placeholder for the real thing - come back soon!</p>
    </Container>
  )
}

export default About