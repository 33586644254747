//Import React components
import React, { Fragment } from 'react';

//Import packages
import { Nav, Navbar } from 'react-bootstrap';
import { FaKeyboard } from "react-icons/fa";
import styled from 'styled-components';

// Custom styles
const Styles = styled.div`
  .navbar {
    color: var(--mainWhite);
    background-color: var(--mainDark);
    /* Scrolling opacity */
    backdrop-filter: saturate(180%) blur(20px);
    opacity: 1;
    z-index: 1;
  }

  .navbar-brand {
    font-weight: 700;
  }

  .navbar-brand:hover {
    color: var(--mainBlue);
  }

  .navbar-nav .nav-link {
    color: var(--mainWhite);
    font-weight: 500;
  }

  .navbar-nav:hover .nav-link:hover {
    color: var(--mainBlue);
  }

  .icon-override {
    margin-bottom: 0.2rem;
    margin-right: 0.3rem;
  }
`;

const NavigationBar = () => {
  return (
    <Fragment>
      <Styles>
        <Navbar fixed="top" variant="dark" expand="md">
          <Navbar.Brand href={`${process.env.PUBLIC_URL}/`}>
            <FaKeyboard
              className="icon-override"
            />{' '}
            SD PORTFOLIO
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              <Nav.Link href={`${process.env.PUBLIC_URL}/about`}>ABOUT</Nav.Link>
              <Nav.Link href={`${process.env.PUBLIC_URL}/blog`}>BLOG</Nav.Link>
              <Nav.Link href={`${process.env.PUBLIC_URL}/projects`}>PROJECTS</Nav.Link>
              <Nav.Link href={`${process.env.PUBLIC_URL}/contact`}>CONTACT US</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </Styles>
    </Fragment>
  )
} 

export default NavigationBar