// Import React modules
import React, { Fragment } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
//require('dotenv').config();
// Import styles
import './App.css';

// Import local components
import Home from './components/views/Home';
import About from './components/views/About';
import Projects from './components/views/Projects';
import Blog from './components/views/Blog';
import Contact from './components/views/Contact';
import NotFound from './components/views/NotFound';

// Import layouts
import Layout from './components/layout/Layout';
import NavigationBar from './components/layout/NavigationBar';
import Footer from './components/layout/Footer';


function App() {
  console.log(process.env.PUBLIC_URL);

  return(
    <Fragment>
      <div className="app-layout">
        <NavigationBar />
        <Layout>
          <Router basename='/sanjithdas/'>
            <Switch>
               
                <Route exact path='/' component={Home}></Route>
                <Route exact path= '/about'  component={About}></Route>
                <Route exact path='/blog' component={Blog}></Route>
                <Route exact path='/projects' component={Projects}></Route>
                <Route exact path='/contact' component={Contact}></Route>
                <Route component={NotFound}></Route>
               
            </Switch>
          </Router>
        </Layout>
        <Footer />
      </div>
    </Fragment>
  );
}

export default App;